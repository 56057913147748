import React, {useEffect } from "react";
import { graphql } from "gatsby";
import Gridreels from "../components/reels";
import Layout from "../components/layout";
import Newsletter from "../components/newsletter";

export const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const PageReels = ({ data }) => {
  //const articles = data.articles.edges;
  //const category = data.category.name;
  const seo = {
    metaTitle: "vídeo recetas fáciles en menos de un minuto",
    metaDescription: "Las mejores video recetas de La Cocina Sana. Recetas sencillas que siempre salen bien",
  };

  return (
    <Layout seo={seo}>
        <h1 className="m-auto poppins px-4  pt-2 md:text-s38 text-s33 leading-8 md:leading-11 pb-6 text-black text-center">Reels Recetas</h1>
        <div className="">
            <Gridreels />
        </div>
        <Newsletter />
    </Layout>
  );
};

export default PageReels;